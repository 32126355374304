export default function useDistinct(array, predicate) {
  const seen = new Set();
  return array.filter((item) => {
    const key = predicate(item);
    if (seen.has(key)) {
      return false;
    }
    seen.add(key);
    return true;
  });
}
