import axios, { AxiosRequestConfig } from "axios";
import deepmerge from "deepmerge";
import Item from "~~/interfaces/Item";

function generateCacheKey(obj) {
  return JSON.stringify(obj);
}

const cache = new Map<string, any>();

const kvArrToObj = (kv: Array<{ key: string; value: string }>) =>
  kv?.reduce?.((a, c) => {
    a[c.key] = c.value;
    return a;
  }, {}) || {};

const useSource = async (
  source,
  overrideOptions: any = {}
): Promise<{
  next: typeof useSource;
  items: Array<Item>;
} | null> => {
  const pageParams = {
    ...(source._provider.pagination === "Page" &&
    source._provider.paginationQuery
      ? {
          [source._provider.paginationQuery]: 1,
        }
      : false),
  };

  const requestOptions: AxiosRequestConfig = {
    baseURL: source._provider.url,
    url: source.path,
    params: {
      ...kvArrToObj(source.query),
      ...kvArrToObj(source._provider.query),
      ...pageParams,
    },
    headers: kvArrToObj(source._provider.headers),
    data: {
      ...kvArrToObj(source.data),
      ...kvArrToObj(source._provider.data),
    },
    method: source._provider.method,
  };

  const mergedRequestOptions = deepmerge(requestOptions, overrideOptions);

  const cacheKey = generateCacheKey(mergedRequestOptions);

  if (cache.has(cacheKey)) {
    return cache.get(cacheKey);
  }

  const request = await axios.request(mergedRequestOptions);

  if (request.status >= 400) {
    console.warn("Response Failed", request.data);
    return null;
  }

  if (!request.data) {
    console.warn("Response Empty", request.data);
    return null;
  }

  let result: { items: Array<Item>; next?: Partial<AxiosRequestConfig> } =
    request.data;

  if (source._provider.postProcessor?.code) {
    try {
      const postProcessor = eval(source._provider.postProcessor.code);
      result = postProcessor(result);
    } catch (e) {
      console.warn("Provider PostProcessor Error", e, request.data);
      return null;
    }
  }

  if (source.nextProcessor?.code) {
    const nextProcessor = eval(source.nextProcessor.code);
    result.next = nextProcessor(result.next);
  }

  if (source.itemProcessor?.code) {
    try {
      const itemProcessor = eval(source.itemProcessor.code);
      result.items = result.items.map((x) => ({
        ...itemProcessor(x),
        _source: source,
      }));
    } catch (e) {
      console.warn("Source Item Processor Error", e, result);
      return null;
    }
  } else {
    result.items = result.items.map?.((x) => ({ ...x, _source: source }));
  }
  let nextOptions: Partial<AxiosRequestConfig>;
  if (
    source._provider.pagination === "Page" &&
    source._provider.paginationQuery
  ) {
    nextOptions = {
      params: {
        [source._provider.paginationQuery]:
          mergedRequestOptions.params[source._provider.paginationQuery] + 1,
      },
    };
  } else if (source._provider.pagination === "Cursor") {
    nextOptions = result.next as Partial<AxiosRequestConfig>;
  }

  const nextFn = () => useSource(source, nextOptions);

  const response = { items: result.items, next: nextFn };

  cache.set(cacheKey, response);

  return response;
};

export default useSource;
