<template>
  <div>
    <div v-if="debug">
      {{ Object.keys(pagesBySource) }}<br>
      {{ Object.keys(lastItemBySource).map(x => ({ [x]: new Date(lastItemBySource[x].date).toLocaleString() })) }}<br>
      {{ allItems.length }}
    </div>
    <!-- <template v-if="isLoadingSem">
      <div class="text-center">
        <span class="loader absolute"></span>
      </div>
    </template>

    <template v-else>
      <div class="text-center pb-6">
      </div>
    </template> -->
    <ul>
      <li v-for="(item, i) in allItems" :key="item.link" :ref="e => onItemLoaded(e, item)"
        :data-source="item._source.slug">
        <a :href="item.link" target="_blank" class="item-card p-8 m-8 bg-white shadow-lg rounded-xl block"
          @click="() => clickItem(item) ">
          <!-- <template v-if="debug">{{ item }}</template> -->
          <div class="grid grid-cols-12">
            <div class="col-span-12"
              :class="(site.showThumbnails && item.thumbnail) ? 'sm:col-span-8 md:col-span-9 lg:col-span-10' : ''">
              <h3 class="font-bold text-xl mb-4">
                {{ useStrip(item.title) }}
              </h3>
              <p class="text-gray-700 mb-2 break-all">
                {{ useStrip(item.summary) }}
              </p>
              <p class="text-gray-600 break-all">
                {{ useStrip(item.preview, 200) }}
              </p>
            </div>
            <div v-if="site.showThumbnails && item.thumbnail"
              class="p-4 col-span-12 sm:col-span-4 md:col-span-3 lg:col-span-2">
              <img :src="item.thumbnail" class="rounded min-w-full thumbnail" />
            </div>
          </div>

          <div class="flex justify-between mt-4">
            <div>
              <span v-if="showCategoryName" v-for="category in categoriesMap[item._source.slug]"
                class="flex-shrink-1 text-gray-300 text-xs px-2 py-1 rounded badge m-2 ml-0"
                :style="{ backgroundColor: category.badgeColor, color: category.badgeTextColor }">
                {{ category.title }}
              </span>

              <span v-if="site.showSourceBadge && item._source.badgeName"
                class="flex-shrink-1 text-gray-300 text-xs px-2 py-1 rounded badge m-2 ml-0"
                :style="{ backgroundColor: item._source.badgeColor, color: item._source.badgeTextColor }">
                {{ item._source.badgeName }}
              </span>

            </div>
            <p v-if="item.date" class="flex-shrink-1 text-gray-300 text-sm mt-2 text-right"
              :title="new Date(item.date).toLocaleString()">
              {{ useDateFormat(item.date, 'YYYY-MM-DD').value }}
            </p>
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ItemList',
})
</script>

<script lang="ts" async setup>
import { computedAsync, } from '@vueuse/core'
import useSource from "@/composables/useSource"
import useStrip from "@/composables/useStrip"
import useDistinct from "@/composables/useDistinct"
import { useDateFormat } from '@vueuse/core'
import sanitizeHtml from 'sanitize-html';
import { useWindowSize } from '@vueuse/core'
import useModal from "@/composables/useModal"
import WarningModal from "@/components/WarningModal"
import useLoader from "@/composables/useLoader"

const startDate = ref(new Date())

const setStartDate = (hoursAgo: number) => {
  const date = new Date();
  date.setHours(date.getHours() - hoursAgo)
  startDate.value = +date;
}

setStartDate(1)

const { open: openModal } = useModal()

const warningsSeen = useLocalSetting('warningsSeen', 1, {});

const clickItem = (item) => {
  if (item._source.requireLogin && item._source.requireLoginWarningID) {
    if (!warningsSeen.value[item._source.requireLoginWarningID]) {
      openModal(h(WarningModal, { item }))
      warningsSeen.value[item._source.requireLoginWarningID] = true
    }
  }
}

interface Props {
  sources: any[],
  showCategoryName?: boolean
}
const props = defineProps<Props>()

const debug = inject('debug')

const {
  site,
  categories,
} = await useData()


const { width } = useWindowSize()


const categoriesMap = computed(() => {
  const result = {}

  props.sources.map(s => {
    result[s.slug] = categories.value.filter(c => c.sources.some(cs => cs === s.slug))
  })
  return result;
})


const pagesBySource = reactive({})
const isLoadingSem = ref(0)

const { attach: attachLoader } = useLoader()
attachLoader(computed(() => !!isLoadingSem.value))
// onBeforeUnmount(() => {
//   detach()
// })

const getNextPageForSource = async (source) => {
  if (!pagesBySource[source.slug]?.next) return
  isLoadingSem.value++
  try {
    const results = await pagesBySource[source.slug].next()
    pagesBySource[source.slug].items.push(...results.items)
    pagesBySource[source.slug].next = results.next
    // console.log(results)
  } catch (e) {
    console.warn(e)
  }
  isLoadingSem.value--;
}


watch(() => props.sources, (n = [], o = []) => {
  const oldSet = new Set((o).map(x => x.slug))
  const newSet = new Set(n.map(x => x.slug))
  const removed = o.filter(x => !newSet.has(x.slug))
  const added = n.filter(x => !oldSet.has(x.slug))

  removed.forEach(r => {
    delete pagesBySource[r.slug]
  })
  added.forEach(a => {
    pagesBySource[a.slug] = { next: () => useSource(a), items: [] }
    getNextPageForSource(a)
  })
}, { immediate: true })

const observer = ref();

const onItemLoaded = (elem, item) => {
  item._elem = elem;
}

onMounted(() => {
  function handleIntersect(entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const sourceSlug = entry.target.dataset.source;
        if (debug.value) {
          console.log("loading next", sourceSlug)
        }
        const source = props.sources.find(x => x.slug === sourceSlug)
        if (source) {
          getNextPageForSource(source)
        }
        observer.unobserve(entry.target);
      }
    });
  }

  observer.value = new IntersectionObserver(handleIntersect, {
    root: null,
    rootMargin: '10000px 0px 1000px 0px',
  });
  onUnmounted(() => {
    observer.value.disconnect();
  });
})


const allItems = computed(() => {
  return useDistinct(Object.values(pagesBySource).flatMap(x => x.items).filter((x) => {
    if (!x) return false;

    const filters = useDistinct([
      ...(site.value.filters || []),
      ...(x._source.filters || []),
      ...((categoriesMap.value[x._source.slug] || []).flatMap(c => c).map(c => c.filters || []))
    ].flatMap(x => x).map(f => f.regex), x => x)

    const filterResult = filters.every(f => {
      const check = !new RegExp(f, "gi").test(x.title)
      if (!check && debug.value) {
        console.log("Filtered: ", x.title, f)
      }
      return check
    })
    return filterResult
  }).map(x => {
    return ({
      ...x,
      link: !x.desktopLink ? x.link : width.value > 600 ? x.desktopLink : x.link
    })
  }), x => x.link).sort((a, b) => {
    try {
      return new Date(b.date) - new Date(a.date)
    } catch (e) {
      return null
    }
  })
})

const lastItemBySource = computed(() => {
  const result = {}
  allItems.value.forEach(i => {
    result[i._source.slug] = i
  })
  return result
})

watch(lastItemBySource, (n, o) => {
  setTimeout(() => {
    if (observer.value) {
      const oldElems = Object.values(o);
      const newElems = Object.values(n);
      const oldSet = new Set(oldElems.map(o => o.link))
      const newSet = new Set(newElems.map(n => n.link))
      const removed = oldElems.filter(x => !newSet.has(x.link))
      const added = newElems.filter(x => !oldSet.has(x.link))
      added.map(x => {
        if (debug.value) {
          console.log(x._elem, "watching")
        }
        observer?.value?.observe(x._elem)
      })
      removed.map(x => {
        if (debug.value) {
          console.log(x._elem, "unwatching")
        }
        if (x._elem) {
          observer?.value?.unobserve(x._elem)
        }
      })

    }
  }, 100)
})

</script>
<style scoped>
.badge {
  word-break: keep-all;
}
.thumbnail {
  max-height: 200px;
  object-fit: cover;
}
</style>
