export default function useStrip(html, maxlength) {
  if (!html) return null;

  let stripped = "";
  if (typeof DOMParser !== "undefined") {
    const doc = new DOMParser().parseFromString(html, "text/html");
    stripped = doc.body.textContent || "";
  } else {
    stripped = html.replace(/<[^>]*>?/gm, "");
  }

  if (stripped.length > maxlength) {
    return stripped.substring(0, maxlength - 3) + "...";
  }
  return stripped;
}
