<template>
  <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">
    {{ language.secureWarningTitle }}
  </DialogTitle>
  <div class="mt-2">
    <p class="text-sm text-gray-500 whitespace-pre-wrap">
      {{ language.secureWarningGeneric }}
    </p>
    <p class="text-sm text-gray-800 mt-8 ">
      {{ item._source.title }}
    </p>
    <p class="text-sm text-gray-500 mt-1">
      {{ item._source.requireLoginText }}
    </p>
  </div>

  <div class="mt-4 text-right">
    <button type="button"
      class="inline-flex justify-center rounded-md border border-transparent bg-orange-100 px-4 py-2 text-sm font-medium text-orange-900 hover:bg-orange-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-orange-500 focus-visible:ring-offset-2 mr-2"
      @click="hideAndClose">
      {{ language.secureWarningBtnHide }}
    </button>
    <button type="button"
      class="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
      @click="close">
      {{ language.secureWarningBtnKeep }}
    </button>
  </div>
</template>

<script setup lang="ts">
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from '@headlessui/vue'
import useModal from "@/composables/useModal"

interface props {
  item: any
}

const props = defineProps<props>()

const {
  language,
  sources
} = await useData()

const { close } = useModal()


const hiddenSources = useLocalSetting('hiddenSources', 1, {});

const hideAndClose = () => {
  hiddenSources.value[props.item._source.slug] = true;

  const siblings = sources.value.filter(x => x.requireLoginWarningID === props.item._source.requireLoginWarningID);
  siblings.forEach(s => {
    hiddenSources.value[s.slug] = true;
  })

  close()
}
</script>
